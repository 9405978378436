.wrapperLoader .extrasmall {
  border: 1px solid white;
  width: 10px;
  height: 10px;
}

.wrapperLoader .small {
  border: 1.2px solid white;
  width: 12px;
  height: 12px;
}

.wrapperLoader .midsmall {
  border: 1.6px solid white;
  width: 16px;
  height: 16px;
}

.wrapperLoader .medium {
  border: 2px solid white;
  width: 20px;
  height: 20px;
}

.wrapperLoader .large {
  border: 2.5px solid white;
  width: 24px;
  height: 24px;
}

.wrapperLoader .loader {
  display: inline-block;
  border-radius: 50%;
  -webkit-animation: spin 0.3s linear infinite;
  animation: spin 0.6s linear infinite;
}

.wrapperLoader .white {
  border-color: white;
  border-top-color: #4284f3;
}

.wrapperLoader .purewhite {
  border-color: var(--white);
  border-top-color: #4284f3;
}

.wrapperLoader .lightblue {
  border-color: var(--lightblue);
  border-top-color: white;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
