#element {
  touch-action: manipulation;
}
.tetris {
  /* height: 100%; */
  padding: 0px;
  /* margin: 0 auto; */
  margin: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.tetris-board {
  display: flex;
  justify-content: space-between;
  width: min-content;
  margin: auto;
  /* height: 80%; */
}

.tetris-board__info {
  position: absolute;
}

.tetris-board__text {
  font-size: 18px;
  color: white;
  padding: 0px 10px;
}

.tetris-board__row {
  display: flex;
}

/* Styles for tiles */
[class*='col-'] {
  /* padding: 17px; */
  padding: 13px;
  border: 1px solid #1a1c19;
}

/* Default (empty) board column */

/* Colors for tiles */
.col-1 {
  background-color: #f21620;
}

.col-2 {
  background-color: #10ac84;
}

.col-3 {
  background-color: #5f27cd;
}

.col-4 {
  background-color: #d925cf;
}

.col-5 {
  background-color: #48dbfb;
}

.col-6 {
  background-color: #fd4964;
}

.col-7 {
  background-color: #72fa4e;
}

/* Styles for buttons */
.tetris__block-controls {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.tetris__game-controls {
  /* margin-top: 16px; */
  margin-top: 6px;
  display: flex;
  justify-content: center;
}

.tetris__game-controls {
  margin-bottom: 16px;
}

.btn {
  background-color: transparent;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  margin-left: 1%;
  margin-right: 1%;
  justify-content: center;
  align-items: center;
  display: flex;
  outline: none;
}
@media screen and (max-width: 760px) and (min-width: 750px) {
  [class*='col-'] {
    padding: 13px 12px;
    border: 1px solid #1a1c19;
  }
}
@media screen and (max-width: 750px) {
  .tetris-board {
    display: flex;
    justify-content: space-between;
    width: min-content;
    margin: auto;
    /* height: 85%; */
  }

  [class*='col-'] {
    padding: 13px 12px;
    border: 1px solid #1a1c19;
  }
  .btn {
    background-color: transparent;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.25s ease-in;
    /* margin-left: 1%;
    margin-right: 1%; */
    margin-left: 0%;
    margin-right: 0%;
    justify-content: center;
    align-items: center;
    display: flex;
    outline: none;
    /* height: 30px;
    width: 50px; */
  }
  .tetris__block-controls {
    margin-top: 4%;
    display: flex;
    justify-content: center;
  }
  .tetris__game-controls {
    /* margin-top: 5%; */
    display: flex;
    justify-content: center;
  }
}
@media screen and (width: 320px) and (height: 568px) {
  [class*='col-'] {
    padding: 9px;
    border: 1px solid #1a1c19;
  }
}
@media screen and (width: 360px) {
  .btn {
    background-color: transparent;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.25s ease-in;
    /* margin-left: 1%;
    margin-right: 1%; */
    margin-left: 0%;
    margin-right: 0%;
    justify-content: center;
    align-items: center;
    display: flex;
    outline: none;
    /* height: 26px; */
    width: 50px;
  }
}
