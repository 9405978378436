.wrapperPopup {
  border: 1px solid #4d4d4f;
  display: flex;
  flex-direction: column;
  background-color: #18181a;
  max-width: 400px;
  border-radius: 8px;
}

.wrapperPopup .button {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #4d4d4f;
  background-color: #4d4d4f;
  font-weight: bold;
  border-radius: 0 0 8px 8px;
}

.wrapperPopup .button button {
  color: #fff;
  background: transparent;
  width: 100%;
  height: 48px;
  outline: none;
  border: 0;
  background-color: #18181a;
}

.wrapperPopup .onlybutton button {
  border-radius: 0 0 8px 8px;
}

.wrapperPopup .bothbutton div:nth-child(1) button {
  border-radius: 0 0 0 8px;
}

.wrapperPopup .bothbutton div:nth-child(2) button {
  border-radius: 0 0 8px 0;
}

.wrapperPopup .button button:hover {
  cursor: pointer;
  background-color: #232325;
}

.wrapperPopup .label1 {
  margin-left: 1px;
  width: 100%;
}

.wrapperPopup .loader {
  display: flex;
  justify-content: space-around;
  justify-self: center;
  margin: auto;
  width: 100%;
}

.wrapperPopup .label {
  width: 100%;
}

.wrapperPopup .text {
  padding: 24px 20px;
  max-height: 60vh;
  overflow: scroll;
  color: #fff;
}

.wrapperPopup .bold button {
  font-weight: bold;
  color: #fff;
}
