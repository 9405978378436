.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  fill: '#fff';
}
.cls-2 {
  font-size: 431px;
  letter-spacing: 0em;
}
.cls-2,
.cls-4 {
  font-family: Arial-BoldMT, Arial;
  font-weight: 700;
}
.cls-3 {
  font-size: 174px;
  font-family: ArialMT, Arial;
  letter-spacing: 0em;
}
.cls-4 {
  font-size: 146px;
}
.cls-5 {
  letter-spacing: -0.04em;
}
.cls-6 {
  letter-spacing: -0.04em;
}
